@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "gilroyextrabold";
  src: url("./assets/fonts/Gilroy-FREE/Gilroy-ExtraBold.otf");
}
@font-face {
  font-family: "gilroylight";
  src: url("./assets/fonts/Gilroy-FREE/Gilroy-Light.otf");
}
@font-face {
  font-family: "gothambold";
  src: url("./assets/fonts/Gotham-Rounded-Font/Gotham-Rounded-Bold.otf");
}
@font-face {
  font-family: "gothammedium";
  src: url("./assets/fonts/Gotham-Rounded-Font/Gotham-Rounded-Medium.otf");
}
@font-face {
  font-family: "gothamlight";
  src: url("./assets/fonts/Gotham-Rounded-Font/Gotham-Rounded-Light.otf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: white;
}
.hl {
  font-family: "gilroylight" !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "gilroyextrabold";
}
p,
.p {
  font-family: "gothamlight";
}
.pm {
  font-family: "gothammedium";
}
.pb {
  font-family: "gothambold";
}
a {
  font-family: "gothamlight";
}
button {
  font-family: "gothamlight";
}
.more-button svg {
  display: none;
}
.more-button:hover svg {
  display: block;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}
.swiper-pagination {
  position: relative !important;
}
.swiper-pagination-bullet-active {
  background: #42af1e !important;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  margin: 5px 7px !important;
}
.f-div-1 {
  background: url(./assets/images/blanc_no_tocxic_750x.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  background-color: #ececec;
}
.f-div-2 {
  /* background: url(./assets/images/sustainability_2_750x.png); */
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  background-color: #ececec;
}

.hs-accordion-group .active .title {
  color: #42af1e !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mark-down h1 {
  font-size: 30px;
  padding: 20px 0px;
}

.mark-down h2 {
  font-size: 20px;
  padding: 20px 0px;
}

.mark-down ol li {
  list-style: auto !important;
 }
 .mark-down strong{
   font-weight: 900;
   /* font-family: "gilroyextrabold"; */
   font-family: "gothambold";
 }
 
@media only screen and (max-width: 600px) {
  .mark-down h1 {
    font-size: 25px !important;
  }
}


.schedule-container .swiper-button-prev{
  top: 30px;
  font-size: 20px !important;
  color: #42af1e !important;
}
.schedule-container .swiper-button-prev::after{

  font-size: 30px !important;

}
.schedule-container .swiper-button-next::after{

  font-size: 30px !important;

}
.schedule-container .swiper-button-next{
  top: 30px;
  font-size: 20px;
  color: #42af1e !important;
}


/* /////////////////////////////////////////////////////////// */

@font-face {
  font-family: "Poppins Regular";
  /* src: url("./assets/fonts/Poppins-Regular.ttf"); */
}
@font-face {
  font-family: "Poppins Medium";
  /* src: url("./assets/fonts/Poppins-Medium.ttf"); */
}
@font-face {
  font-family: "Poppins SemiBold";
  /* src: url("./assets/fonts/Poppins-SemiBold.ttf"); */
}
@font-face {
  font-family: "Poppins Bold";
  /* src: url("./assets/fonts/Poppins-Bold.ttf"); */
}







/**************************************
    TABS
**************************************/
.tln-tabs-nav .nav-link {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: -0.5px;
  /* color: #146031; */
  color: #46B035;
}
.tln-tabs-nav .nav-link.active {
  border-bottom: 1px solid #146031;
  color: #146031;
}

.tln-browsing-tabs .nav-item {
  max-width: 200px;
  width: 100%;
}
.tln-browsing-tabs .nav-item .nav-link {
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0.5rem;
  color: #000000;
  background-color: #fff;
  border: 1px solid #009345;
}
.tln-browsing-tabs .nav-item .nav-link .nav-link-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tln-browsing-tabs .nav-item .nav-link .close-icon {
  cursor: pointer;
}
.tln-browsing-tabs .nav-item .nav-link .close-icon img {
  width: 16px;
}
.tln-browsing-tabs .nav-item .nav-link.active {
  color: #fff;
  /* background-color: #146031; */
  background-color: #46B035;
  border: 1px solid #146031;
}
.tln-browsing-tabs .nav-item .nav-link.active .close-icon img {
  filter: invert(1);
}
.tln-browsing-tabs .nav-item.nav-item-for-new-tab {
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.tln-browsing-tabs .nav-item.nav-item-for-new-tab .nav-link {
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
}






/***************************************
   SideBar
***************************************/

.sidebar-nav > ul > li > a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  background-color: #7aa48c;
  /* background-color: #46B035; */
  color: #fff;
  padding: 10px 16px;
  font-family: "Poppins Medium";
  font-weight: 500;
  border-bottom: 1px solid #fff;
  transition: all 0.9s;

}
.sidebar-nav > ul > li > a .menu-item-text {
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 
}
.sidebar-nav > ul > li > a .menu-item-arrow-icon {
  display: flex;
  align-items: center;
  
}
.sidebar-nav > ul > li > a .menu-item-arrow-icon svg {
  transition: 0.3s;
  transform: rotate(0deg);
  transition: all 0.6s; 
}
.sidebar-nav > ul > li > a:hover {
  /* background-color: #146031; */
  background-color: #46B035;
}
.sidebar-nav > ul > li > a.active {
  /* background-color: #146031; */
  background-color: #46B035;
}

.sidebar-nav > ul > li > a.active .menu-item-arrow-icon svg {
  transform: rotate(180deg);
}
.sidebar-nav > ul > li > ul > li > a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  background-color: #fff;
  color: #000;
  padding: 10px 16px;
  font-family: "Poppins Medium";
  font-weight: 500;
  border-bottom: 1px solid #dee2e6;
  transition: 0.3s;
}

.has-sub-menu.open > .sub-menu {
  display: block;
}
.sub-menu {
  display: none;
}






/***************************************
    FOOTER
***************************************//*# sourceMappingURL=style.css.map */




